.grupos-cargados {
  padding: 0px 0px 80px 0px;
  text-align: center;
  h1,
  h2,
  h3 {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 600 !important;
    font-size: 1rem;
  }

  h4 {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 600 !important;
    font-style: normal !important;
  }

  h4 i {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
  }

  th,
  td {
    font-size: x-small;
  }

  .table-bordered td,
  .table-bordered th {
    font-weight: 600;
    border: 1px solid #dee2e6;
    padding-block: 0px;
    padding: 2px 5px;
    margin: 0px;
    margin-block: 0px;
    height: auto;
  }

  table {
    // width: fill-available;
    // width: -moz-available;
    th {
      text-align: center;
    }
  }
}
