.Calif {
    text-align: center;
  table {
    width: max-content;
    margin: auto;
  }
  
  table th {
    text-align: center;
  }
  h1, h2, h3 {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 600 !important;
    font-size: 1.5rem;
  }

  h4 {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 600 !important;
    font-style: normal !important;
    font-size: 1.2rem;
    margin-block-end: 1.5rem;
  }
  
  h4 i {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
  }

  @media screen and (max-width:768px) {
    padding: 0;

    .table-sm th {
      font-size: 0.8rem;
    }

    table {
      width: stretch;
      width: -moz-available;
    }

    td {
      font-size: 0.7rem;
    }
  }

  @media screen and (min-width:768px) {
    table th {
      min-width: 30px;
    }
  }
}

