.avanceRet {
  padding: 0px 0px 80px 0px;
  text-align: center;

  .boton {
    display: none;
  }

  table th {
    text-align: center;
  }
  h1 {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 600 !important;
  }
  h3 {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 600 !important;
  }
  h4 {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 600 !important;
    font-style: normal !important;
  }

  h4 i {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
  }

  th,
  td {
    font-size: x-small;
  }

  .table {
    border-collapse: collapse;
  }

  .table-bordered td,
  .table-bordered th {
    font-weight: 600;
    border: 1px solid #dee2e6;
    padding-block: 0px;
    padding: 2px 5px;
    margin: 0px;
    margin-block: 0px;
    height: auto;
  }

  .table > div:nth-child(1) > table:nth-child(8) {
    width: 100%;
  }

  .table
    > div:nth-child(1)
    > table:nth-child(5)
    > tbody:nth-child(1)
    > tr:nth-child(1)
    > th {
    background-color: #ececec;
  }

  .table
    > div:nth-child(1)
    > table:nth-child(10)
    > tbody:nth-child(1)
    > tr:nth-child(1)
    > th:nth-child(1) {
    background-color: #ececec;
  }

  .table
    > div:nth-child(1)
    > table:nth-child(6)
    > tbody:nth-child(1)
    > tr:nth-child(3) {
    background-color: #ececec;
  }

  .table
    > div:nth-child(1)
    > table:nth-child(6)
    > tbody:nth-child(1)
    > tr:nth-child(1) {
    background-color: #ececec;
  }

  .medium_negrita_center,
  #par {
    background-color: #ececec;
  }

  #amarillo {
    background-color: #ffff66;
  }

  #lila {
    background-color: #603060;
    color: #ffffff;
  }

  #verde {
    background-color: #a0d79f;
    color: #000000;
    font-weight: 500;
  }

  #naranja {
    background-color: #ff9900;
    color: #000000;
  }

  #azul {
    background-color: #0080c0;
    color: #ffffff;
  }

  #rosa {
    background-color: #ff00cd;
    color: #ffffff;
  }

  #caffe {
    background-color: #669900; /*#A0522D;*/
    color: #ffffff;
  }

  #gris {
    background-color: #cccccc;
    color: #000000;
    font-weight: 500;
  }

  #rojo {
    background: #ff0000;
    color: #ffffff;
  }

  #verde_s {
    background-color: #669900; /* #669900;*/
    color: #ffffff;
  }

  .small_center {
    padding: 0px;
  }
}

@media (max-device-width: 500px) {
  .Calif{
    h4 i {
      font-size: 1.1rem;
    }
  }
}
