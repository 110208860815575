.horario {
  padding: 20px 0px 10px 0px;
  text-align: center;

  img {
    display: none;
  }

  table {
    margin-block-end: 15px
  }

  .boton {
    display: none;
  }

  .table > div:nth-child(1) > div:nth-child(13) {
    display: none;
  }

  .table > div:nth-child(1) > div:nth-child(18) {
    display: none;
   } 

  .table > div:nth-child(1) > div:nth-child(14) {
    display: none;
   }
   

  .small_left {
    font-size: 9px;
    font-weight: normal;
    text-align: left;
  }

  h1,
  h2,
  h3 {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 600 !important;
    font-size: 1rem;
  }

  h4 {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 600 !important;
    font-style: normal !important;
  }

  h4 i {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
  }

  th,
  td {
    font-size: x-small;
  }

  .table-bordered td,
  .table-bordered th {
    font-weight: 600;
    border: 1px solid #dee2e6;
    padding-block: 0px;
    padding: 2px 5px;
    margin: 0px;
    margin-block: 0px;
    height: auto;
  }

  table {
    //   width: fill-available;
    //   width: -moz-available;
    //   min-width: max-content;
    th {
      text-align: center;
    }
  }
  
  .button-sort {
    position:fixed;
    bottom: 10px;
    right: 10px;
  }

  @media only screen and (min-width: 768px) {
    .table > div:nth-child(1) > table:nth-child(11) {
      white-space: pre;
      width: auto;
    }

    .table > div:nth-child(1) > table:nth-child(11) > tbody > tr > td {
      font-size: .7rem;
    }

    .table > div:nth-child(1) > br {
      display: none;
    }

  }

  @media only screen and (max-width: 768px) {
    .horario .table-bordered td,
    .horario .table-bordered th {
    width: max-content;
    }
    table {
      width: auto;
    }
    .table > div:nth-child(1) > table:nth-child(11) > tbody > tr > td:nth-child(2),
    .table > div:nth-child(1) > table:nth-child(11) > tbody > tr th.small_negrita_center:nth-child(2),
    .table > div:nth-child(1) > table:nth-child(11) > tbody > tr > td:nth-child(3),
    .table > div:nth-child(1) > table:nth-child(11) > tbody > tr > th.small_negrita_center:nth-child(3),
    .table > div:nth-child(1) > table:nth-child(11) > tbody > tr:last-child {
      display: none;
    }
    .button-sort {
      font-size: 1rem;
      opacity: 0.7;
      border-radius: 2rem;
    }
  }
}
